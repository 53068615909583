import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const TextAreaField = ({name, rows, placeholder, required, handlechange, fieldClass, label, grpmd,errorMsg}) => (
  <Form.Group as={Col} md={grpmd} controlId="validation{name}">
    { label
      ? <Form.Label>{label}{required ? ' *' : ''}</Form.Label>
      : ''
    }
    <Form.Control
      className={fieldClass}
      name={name}
      required={required}
      as="textarea"
      rows={rows}
      onChange={handlechange}
      placeholder={placeholder} 
    />
    {/* {errorMsg && <div className={`error_msg ${name}`}>{errorMsg}</div>} */}
  </Form.Group>
);

export default TextAreaField;