import React from 'react'
import { Form } from "react-bootstrap"

function myplaceholder(props) {
  return <div>{props.html}</div>;
}

function createMarkup(props) {
  return {__html: (props.html)};
}

function MyComponent(props) {
  return <div className="checkbox_label" dangerouslySetInnerHTML={createMarkup(props)} />;
}

const CheckboxField = ({fieldClass, name, value, required, placeholder, handlechange}) => (
  <div className={`form-group ${fieldClass}`}>
      <label className="check-card ">
          <input required={required} className="form-control" type="checkbox" id={name} name={name} value={value} onChange={handlechange} />
          <span className="check-mark"></span>
          <MyComponent html={placeholder} />
      </label>
  </div>
);

export default CheckboxField