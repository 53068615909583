import React, {useRef} from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Select, { components } from "react-select";
import "../../SelectBox/SelectBox.scss";

const SelectField = ({name, required, placeholder, labelClass, values, handlechange, fieldClass, label, grpmd, componentprops, isError, selectedValue, divclass, multiple, bottom_text, errorMsg, ref}) => { 
  const selectOption = [];
  values.map((item,index) => {
    if(item === "Select"){
      selectOption.push({ value: item, label: placeholder, type:"select", name:name})
    } else{
      selectOption.push({ value: item, label: item, type:"select", name:name})
    }
    
  })

  //console.log("isError", isError);
  //console.log("componentprops ==>", selectedValue, componentprops)
  
  return(
  <Form.Group as={Col} md={grpmd} controlId={"validation" + name} id={"validation" + name} className={divclass}>
    { label
      ? <Form.Label className={labelClass}>{label}{required ? ' *' : ''}</Form.Label>
      : ''
    }
    {/* <Form.Control
		className={fieldClass}
		required={required}
		name={name}
		onChange={handlechange}
		as="select"
    value={componentprops ? componentprops[name] : ''}
		>
		{values.map(val =><option value={val} key="">{val}</option>)}
    </Form.Control> */}
    <div className={"select-group "+divclass+" "+ (isError ? "has-error" : "")}>
      {
        multiple ? 
        <Select
          ref={ref}
          className={fieldClass}
          isSearchable={false}
          placeholder={placeholder}
          name={name}
          value={selectedValue ? selectedValue : componentprops ? componentprops[name] : ''}
          // defaultValue={selectedValue ? selectOption.filter(function(option) {
          //   return option.value === selectedValue;
          // }) : selectOption[0]}
          onChange={(e)=>handlechange(e, 'select')}
          options={selectOption}
          required={required}
          classNamePrefix={"select-opt"}
          isMulti={multiple}
        /> 
      :
        <Select
          ref={ref}
          className={fieldClass}
          isSearchable={false}
          placeholder={placeholder}
          name={name}
          //value={selectedValue ? selectedValue : componentprops ? componentprops[name] : ''}
          //value={componentprops ? componentprops[name] : ''}
          // defaultValue={selectedValue ? selectOption.filter(function(option) {
          //   return option.value === selectedValue;
          // }) : selectOption[0]}
          onChange={handlechange}
          options={selectOption}
          required={required}
          isError={required}
          classNamePrefix={"select-opt"}
          isMulti={false}
        />
      }

      {bottom_text && <div className="file_formats">{bottom_text}</div>}
    </div>
    {/* {errorMsg && <div className={`error_msg ${name}`}>{errorMsg}</div>} */}
  </Form.Group>
  )
}

export default SelectField;