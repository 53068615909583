import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DatePicker from "react-datepicker";

import CalendarIcon from "../assets/img/calendar-icon.png"
 
import "react-datepicker/dist/react-datepicker.css";



const InputField = ({startDate, setStartDate,fieldClass, labelClass, type, name, value, required, placeholder, handlechange, pattern, label, grpmd, step, inputlabel, max, maxlength, divclass, errorMsg, startTime, setStartTime}) => {
  
  const [date_value, onChange] = useState(new Date());
  if(type == 'datetime' && date_value) {
    var month = date_value.getMonth() + 1
    var date = date_value.getDate() + '/' + month + '/' + date_value.getFullYear()
  }

  //console.log("type", type);

return (

  <Form.Group as={Col} md={grpmd} className={`${type} ${step} ${divclass}`} controlId={"validation" + name}>
    <div className="custom-float">
    { label
      ? <Form.Label className="form-label">{label}{required ? ' *' : ''}</Form.Label>
      : ''
    }
    {type === 'datetime' ?
    <>
    <input type="hidden" name="valuation_book_date" value={date} />
    <Calendar
       onChange={onChange}
        value={date_value}
    />
    </> : type === 'valuation_date' ? <>
    <DatePicker
      selected={startDate}
      onChange={date => setStartDate(date)}
      showTimeSelect={false}
      minDate={new Date()}
      placeholderText={placeholder}
      dateFormat="MMMM d, yyyy"
      timeFormat="hh:mm aa"
      className={fieldClass}
      required={required}
      autocomplete="off"
      name={name}
      autoComplete={'' + Math.random()}                
      // onChange={handlechange}
      value={startDate}
      //calendarIcon={<img src={CalendarIcon} />}      
    />
     {/* <img className="cal_icon" src={CalendarIcon} alt="Calender Icon" title="" /> */}
  </>
: type === 'valuation_time' ? <>
  <DatePicker
    placeholderText={placeholder}
    timeFormat="hh:mm aa"
    className={fieldClass}
    required={required}
    autocomplete="off"
    name={name}
    autoComplete={'' + Math.random()}
    selected={startTime}
    onChange={(date) => setStartTime(date)}
    showTimeSelect
    showTimeSelectOnly
    timeCaption="Time"
    dateFormat="h:mm aa"
    value={startTime}
  /> 
  {/* <img className="cal_icon" src={CalendarIcon} alt="Calender Icon" title="" /> */}
</>
:
  <Form.Control
    className={fieldClass}
    required={required}
    type={type}
    name={name}
    value={value}
    placeholder={placeholder}
    onChange={handlechange}
    pattern={pattern}
    autoComplete={type === "text" ? (name === "telephone" ? name : "new_"+name) : "off"}
    max={max}
    maxLength={maxlength}

  /> 
}
</div>
{/* {errorMsg && <div className={`error_msg ${name}`}>{errorMsg}</div>} */}
</Form.Group>
)}

export default InputField;